import { inject } from 'vue'
import store from '@/store'

const valuationRoutes = [
    {
        name: 'dvm',
        redirect: {
            name: 'valuation.request',
        },
        path: '/dvm',
    },
    {
        name: 'valuation',
        redirect: {
            name: 'valuation.request',
            params: {
                action: 'start',
            },
        },
        path: '/valuation',
    },
    {
        name: 'valuation.request',
        component: () => import('./components/ValuationRequest.vue'),
        path: '/valuation/request/:action?',
        // beforeEnter: (to, from, next) => {
        //     console.debug('valuation.request.beforeEnter', to, from)
        // },
        // TODO: split into separate routes per action, and add meta to first step:
        // meta: {
        //     resetCurRequest: true,
        // },
    },
    {
        name: 'valuation.edit',
        path: '/valuation/request/edit',
        redirect: { name: 'valuation.request' },
        // beforeEnter: (to, from, next) => {
        //     // NOTE: logic moved inside `valuation.request` since we need the request to be loaded
        //     // let action = 'extra-info'
        //     // if (!store.state.valuationStore.Address.features.f_building_type) {
        //     //     action = 'building-type'
        //     // } else if (
        //     //     ['apartment', 'house'].includes(
        //     //         store.state.valuationStore.Address.features.f_building_type
        //     //     )
        //     // ) {
        //     //     if (!store.state.valuationStore.Address.address.streetname) {
        //     //         action = 'address'
        //     //     } else if (!store.state.valuationStore.Address.features.building_id) {
        //     //         // TODO: add match_address here
        //     //         console.warn('must run match_address here')
        //     //         // await store.dispatch('valuationStore/Address/match_address')
        //     //         action = 'confirm-main-building'
        //     //     }
        //     // }
        //     return next({
        //         ...to,
        //         name: 'valuation.request',
        //     })
        // },
    },
    {
        name: 'valuation.valuer-settings',
        path: '/valuation/valuer-settings',
        component: () => import('./components/ValuerSettings/ValuerSettings.vue'),
    },
    {
        name: 'valuation.dispatcher-settings',
        path: '/valuation/dispatcher-settings',
        component: () => import('./components/DispatcherSettings/DispatcherSettings.vue'),
    },
    {
        name: 'valuation.ovm.borrower',
        path: '/valuation/ovm/borrower/:action?',
        component: () => import('./components/request/ovm/borrower/Borrower.vue'),
    },
    {
        name: 'valuation.ovm.summary',
        path: '/valuation/ovm',
        component: () => import('./components/request/ovm/Summary.vue'),
    },
    {
        name: 'valuation.onsite.summary',
        path: '/valuation/onsite',
        component: () => import('./components/request/ovm/Summary.vue'),
    },
    {
        name: 'valuation.dvm',
        component: () => import('./components/dvm/Dvm.vue'),
        path: '/valuation/dvm',
        beforeEnter: (to, _from, next) => {
            const config = inject('config')
            if (!config.ENABLE_DVM || (config.CHECK_LOGIN && !store.getters.getCurUser)) {
                console.warn('DVM is disabled or user is not a DVM valuer: Redirecting to dashboard')
                return next({ name: 'dashboard', params: { lang: to.params.lang } })
            }
            return next()
        },
    },
    {
        name: 'valuation.report',
        component: () => import('./components/report/Report.vue'),
        path: '/valuation/report',
        meta: {
            public: true,
        },
    },
    {
        name: 'valuation.flood-report',
        component: () => import('./components/report/FloodReport.vue'),
        path: '/valuation/flood-report',
    },
    {
        name: 'valuation.valuer-app',
        component: () => import('./components/ValuerTool/ValuerTool.vue'),
        path: '/valuation/valuer-app',
        children: [
            {
                name: 'valuation.valuer-app.overview',
                path: 'overview',
                component: () => import('./components/ValuerTool/pages/Overview.vue'),
            },
            {
                name: 'valuation.valuer-app.general',
                path: 'general-info',
                component: () => import('./components/ValuerTool/pages/General/General.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.general.title',
                        prevRoute: 'valuation.valuer-app.overview',
                    },
                },
            },
            {
                name: 'valuation.valuer-app.general.analytics',
                path: 'analytics',
                component: () => import('./components/ValuerTool/pages/General/AnalyticsPage.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.general.value.analytics.title',
                        prevRoute: 'valuation.valuer-app.general',
                    },
                },
            },
            {
                name: 'valuation.valuer-app.ref_map',
                path: 'map',
                component: () => import('./components/ValuerTool/pages/RefMap/RefMap.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.general.reference_map.title',
                        prevRoute: 'valuation.valuer-app.general',
                        navbarComponent: () =>
                            import('./components/ValuerTool/pages/RefMap/MobileToggle.vue'),
                    },
                },
            },
            {
                name: 'valuation.valuer-app.exterior',
                path: 'exterior',
                component: () => import('./components/ValuerTool/pages/Exterior/Exterior.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.exterior.title',
                        prevRoute: 'valuation.valuer-app.overview',
                    },
                },
            },
            {
                name: 'valuation.valuer-app.surroundings',
                path: 'surroundings',
                component: () => import('./components/ValuerTool/pages/Surroundings/Surroundings.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.surroundings.title',
                        prevRoute: 'valuation.valuer-app.overview',
                    },
                },
            },
            {
                name: 'valuation.valuer-app.commons',
                path: 'commons',
                component: () => import('./components/ValuerTool/pages/Commons/Commons.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.commons.title',
                        prevRoute: 'valuation.valuer-app.overview',
                    },
                },
            },
            {
                name: 'valuation.valuer-app.interior',
                path: 'interior',
                component: () => import('./components/ValuerTool/pages/Interior/Interior.vue'),
                redirect: {
                    name: 'valuation.valuer-app.interior.info',
                },
                children: [
                    {
                        name: 'valuation.valuer-app.interior.info',
                        path: 'info',
                        component: () =>
                            import('./components/ValuerTool/pages/Interior/tabs/InteriorTab.vue'),
                        meta: {
                            mobile: {
                                title: 'valuer_tool.interior.title',
                                prevRoute: 'valuation.valuer-app.overview',
                            },
                        },
                    },
                    {
                        name: 'valuation.valuer-app.interior.floors',
                        path: 'floors',
                        component: () =>
                            import('./components/ValuerTool/pages/Interior/tabs/Floorplan/Floors.vue'),
                        meta: {
                            mobile: {
                                title: 'valuer_tool.interior.title',
                                prevRoute: 'valuation.valuer-app.overview',
                            },
                        },
                    },
                    {
                        name: 'valuation.valuer-app.interior.floorplan',
                        path: 'floorplan',
                        redirect: {
                            name: 'valuation.valuer-app.interior.floorplan.floors',
                        },
                        children: [
                            {
                                name: 'valuation.valuer-app.interior.floorplan.rooms',
                                path: 'rooms',
                                component: () =>
                                    import('./components/ValuerTool/pages/Interior/tabs/Floorplan/Rooms.vue'),
                                meta: {
                                    mobile: {
                                        title: 'valuer_tool.interior.floors.floor_mobile_title',
                                        title_param: 'floor',
                                        prevRoute: 'valuation.valuer-app.interior.floorplan.floors',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: 'valuation.valuer-app.value',
                path: 'value',
                component: () => import('./components/ValuerTool/pages/Valuation/Valuation.vue'),
                meta: {
                    mobile: {
                        title: 'valuer_tool.valuation.title',
                        prevRoute: 'valuation.valuer-app.overview',
                    },
                },
            },
        ],
    },
    {
        name: 'valuation.dev-payment',
        component: () => import('./components/request/ovm/borrower/Payment.vue'),
        path: '/valuation/payment/request/:request',
    },
]

export default valuationRoutes
