<template>
    <div class="row items-center" :style="$slots.default ? '' : 'display: inline-flex;'">
        <slot v-if="$slots.default" />
        <template v-if="!props.hideTooltipIcon">
            <!-- <q-avatar
                v-else-if="props.isWarning"
                color="white"
                text-color="warning"
                class="help-pointer"
                :class="{ 'q-ml-xs': !props.noPadding, 'q-mb-xs': !props.noPadding }"
                icon="mdi-alert"
            ></q-avatar> -->
            <div
                class="tooltip-trigger"
                :class="{
                    [`re-${props.size}`]: $props.size,
                    // 'q-ml-sm': !props.noPadding,
                    // 'q-mb-sm': !props.noPadding,
                    [props.triggerColor]: !props.isWarning,
                    'bg-warning text-white': props.isWarning,
                    exponent: props.isExp,
                    smaller: props.isSmaller || props.isExp,
                }"
            >
                <div class="tooltip-trigger--container">
                    <q-icon v-if="props.icon" :name="`${props.icon}`" />
                    <q-icon v-else-if="props.isWarning" name="mdi-alert" />
                    <span v-else>{{ props.triggerText }}</span>
                </div>
            </div>
        </template>
        <q-tooltip
            v-if="!props.hidden"
            v-bind="{ ...tooltipAttrs(props.direction), ...$attrs }"
            class="bg-accent text-body text-white text-body1"
            max-width="30em"
            :class="props.tooltipClasses"
        >
            <slot v-if="$slots.content" name="content"></slot>
            <vue-markdown v-else-if="props.isMarkdown" :source="props.label" />
            <template v-else>
                {{ props.label }}
            </template>
        </q-tooltip>
    </div>
</template>
<script setup>
import VueMarkdown from 'vue-markdown-render'

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    triggerText: {
        type: [String, Number],
        default: '?',
    },
    triggerColor: {
        type: String,
        default: 'bg-primary-light text-primary',
    },
    hideTooltipIcon: {
        type: Boolean,
        default: false,
    },
    isWarning: {
        type: Boolean,
        default: false,
    },
    tooltipClasses: {
        type: Array,
        default: () => [],
    },
    direction: {
        type: String,
        default: 'top',
        validator(value) {
            // The value must match one of these strings
            return ['top', 'right', 'bottom', 'left'].includes(value)
        },
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: undefined,
    },
    isMarkdown: {
        type: Boolean,
        default: false,
    },
    isExp: {
        type: Boolean,
        default: false,
    },
    isSmaller: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: undefined,
    },
    hidden: {
        type: Boolean,
        default: false,
    },
})

const tooltipAttrs = (direction) => {
    switch (direction) {
        case 'right':
            return {
                anchor: 'center right',
                self: 'center left',
                transitionShow: 'jump-right',
                transitionHide: 'jump-left',
            }
        case 'left':
            return {
                anchor: 'center left',
                self: 'center right',
                transitionShow: 'jump-left',
                transitionHide: 'jump-right',
            }
        case 'bottom':
            return {
                anchor: 'bottom middle',
                self: 'top middle',
                transitionShow: 'jump-down',
                transitionHide: 'jump-up',
            }
        case 'top':
        default:
            return {
                anchor: 'top middle',
                self: 'bottom middle',
                transitionShow: 'jump-up',
                transitionHide: 'jump-down',
            }
    }
}
</script>
<style lang="scss" scoped>
// Overriding defaults for markdown display within tooltips
:deep(h3) {
    color: white;
    font-size: 1.3rem;
}

.tooltip-trigger {
    display: inline-block;
    cursor: help;
    text-align: center;
    border-radius: 100%;
    font-weight: 900;
    margin-left: 0.5em;
    position: relative;
    width: 1.15em;
    height: 1.15em;

    &--container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -0.5em);
        font-size: 0.8em;
        line-height: 0.9em;

        .q-icon {
            display: block;
            font-size: 1em;
        }
    }

    &.exponent {
        transform: translateY(-0.25em);
        margin-left: 0.15em;
    }
    &.smaller {
        width: 0.75em;
        height: 0.75em;

        .tooltip-trigger--container {
            font-size: 0.5em;
        }
    }
}
</style>
