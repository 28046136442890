<template>
    <template v-if="props.isHorizontal">
        <template v-if="props.alwaysShowHorizontal || !stepper.currentStep.hidden">
            <template v-if="$q.screen.gt.xs">
                <!-- TODO: rewrite with own CSS -->
                <q-stepper :model-value="stepper.currentStep?.rootName" flat>
                    <q-step
                        v-for="(step, i) in props.stepper.visibleSteps"
                        :key="`step_${i}`"
                        :name="step.name"
                        :prefix="step.visibleStepIdx + 1"
                        :title="step.label"
                        :done="step.done"
                        :icon="step.icon"
                        class="hidden"
                    />
                </q-stepper>
                <q-separator />
            </template>
            <div v-else>
                <div class="row items-center q-pa-md sticky-top">
                    <template v-if="stepper.totalVisibleSteps >= 10">
                        <q-avatar
                            v-if="stepper.currentStep.icon"
                            color="primary-light"
                            class="text-primary"
                            size="md"
                            :icon="stepper.currentStep.icon"
                        />
                        <p class="q-my-auto q-ml-md text-body2">
                            <strong>{{ stepper.currentStep.label }}</strong>
                        </p>
                        <q-badge class="q-ml-auto q-mr-lg text-body1" color="info">
                            <strong>
                                {{ stepper.currentStep.visibleStepIdx + 1 }} / {{ stepper.totalVisibleSteps }}
                            </strong>
                        </q-badge>
                    </template>
                    <template v-for="(step, i) in props.stepper.visibleSteps" v-else :key="i">
                        <re-icon
                            v-if="step.done"
                            name="mdi-check"
                            class="q-pa-sm bg-primary-bground rounded-borders q-mr-sm"
                            color="primary"
                        />
                        <strong v-else-if="step.active" class="text-primary">
                            {{ stepper.currentStep.label }}
                        </strong>
                        <div
                            v-else
                            class="future-step text-secondary bg-secondary-bground rounded-borders row items-center justify-center q-ml-sm"
                        >
                            <strong>{{ i + 1 }}</strong>
                        </div>
                    </template>
                </div>
                <q-separator />
            </div>
        </template>
    </template>
    <q-list v-else v-bind="$attrs" class="stepper">
        <slot name="steps-prepend" />
        <q-item
            v-for="(step, i) in props.stepper.visibleSteps"
            :key="`step_${i}`"
            :active="step.active"
            :class="{ 'done-step': step.done }"
        >
            <q-item-section avatar>
                <q-avatar size="xl" :icon="step.done ? 'mdi-check' : step.icon">
                    <template v-if="!step.done && !step.icon">{{ step.visibleStepIdx + 1 }}</template>
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <q-item-label class="header">
                    {{ step.label }}
                </q-item-label>
                <q-item-label v-if="step.caption" caption>
                    {{ step.caption }}
                </q-item-label>
            </q-item-section>
        </q-item>
        <slot name="steps-append" />
    </q-list>
</template>
<script setup>
const props = defineProps({
    stepper: {
        type: Object,
        required: true,
    },
    isHorizontal: {
        type: Boolean,
        default: false,
    },
    alwaysShowHorizontal: {
        type: Boolean,
        default: false,
    },
})
</script>
<style lang="scss" scoped>
.stepper {
    margin-top: 80px;

    .q-item {
        .q-avatar {
            background-color: $blue-grey-1;
            color: $secondary;
        }
        &.done-step {
            .q-avatar {
                background-color: $positive-bground;
                color: $positive;
            }
        }
        &.q-router-link--active {
            .q-item__label.header {
                font-weight: bold;
            }
            .q-avatar {
                background-color: $blue-1;
                color: $primary;
            }
        }
    }
}
.future-step {
    width: 30px;
    height: 30px;
    &:first-of-type {
        margin-left: auto;
    }
}
</style>
