<template>
    <div class="static">
        <div
            v-if="props.label"
            class="row items-center no-wrap q-col-gutter-x-md"
            :class="[props.justify ? `justify-${props.justify}` : 'justify-start']"
            v-bind="$attrs"
        >
            <div class="col-auto left-icon">
                <re-svg v-if="props.svgName" :name="props.svgName" :subdir="props.svgSubdir" />
                <re-icon v-if="props.icon" size="sm" :color="props.iconColor" :name="icon" />
                <slot v-else-if="$slots.img" name="img"></slot>
            </div>
            <div v-if="label !== undefined" class="col-auto text-secondary">{{ props.label }}:</div>
            <div :class="props.dense ? 'col-auto' : 'col'"><slot></slot></div>
        </div>
        <div
            v-else
            class="row items-center no-wrap q-gutter-x-sm"
            :class="[props.justify ? `justify-${props.justify}` : 'justify-start']"
            v-bind="$attrs"
        >
            <re-svg v-if="props.svgName" :name="props.svgName" :subdir="props.svgSubdir" />
            <re-icon v-if="props.icon" size="sm" :color="props.iconColor" :name="icon" />
            <slot v-else-if="$slots.img" name="img"></slot>
            <span><slot></slot></span>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    label: {
        type: String,
        default: undefined,
    },
    icon: {
        type: String,
        default: undefined,
    },
    iconColor: {
        type: String,
        default: 'primary',
    },
    src: {
        type: String,
        default: undefined,
    },
    svgName: {
        type: String,
        default: undefined,
    },
    svgSubdir: {
        type: String,
        default: undefined,
    },
    justify: {
        type: String,
        default: undefined,
    },
})
</script>
<style lang="scss" scoped>
.static {
    .left-icon {
        min-width: 44px;
        text-align: left;
    }
}
</style>
